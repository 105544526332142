import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedOrgId: '',
    selectedOrgAccess: '',
    selectedOrgName: '',
    selectedOrgMembers: null,
    orgs: [],
}
export const orgSlice = createSlice({
    name: 'dashboard/organization',
    initialState,
    reducers: {
        setSelectedOrgMembers: (state, action) => {
            state.selectedOrgMembers = action.payload
        },
        setSelectedOrg: (state, action) => {
            state.selectedOrgId = action.payload.orgId
            state.selectedOrgAccess = action.payload.access
            state.selectedOrgName = action.payload.name
        },
        setOrgs: (state, action) => {
            state.orgs = action.payload
        },
        resetOrgData: (state, action) => initialState,
    },
})

export const { setSelectedOrg, setSelectedOrgMembers, setOrgs, resetOrgData } =
    orgSlice.actions

export default orgSlice.reducer
