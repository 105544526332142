import { combineReducers } from '@reduxjs/toolkit'
import org from './orgSlice'
import project from './projectSlice'
import bucket from './bucketSlice'
import bundle from './bundleSlice'

const reducer = combineReducers({
    org,
    project,
    bucket,
    bundle,
})

export default reducer
