import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedBucketName: '',
    selectedBucketId: '',
    buckets: [],
}
export const bucketSlice = createSlice({
    name: 'dashboard/bucket',
    initialState,
    reducers: {
        setSelectedBucket: (state, action) => {
            state.selectedBucketName = action.payload.name
            state.selectedBucketId = action.payload.bucketId
        },
        setBucketList: (state, action) => {
            state.buckets = action.payload
        },
        resetBucketData: () => initialState,
        clearSelectedBucket: (state) => {
            state.selectedBucketName = ''
            state.selectedBucketId = ''
        },
    },
})

export const {
    setSelectedBucket,
    setBucketList,
    clearSelectedBucket,
    resetBucketData,
} = bucketSlice.actions

export default bucketSlice.reducer
