import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedTab: '',
    androidBundles: [],
    iosBundles: [],
    selectedBundle: null,
}
export const bucketSlice = createSlice({
    name: 'dashboard/bucket',
    initialState,
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
        setBundleLists: (state, action) => {
            state.androidBundles = action.payload.androidBundles
            state.iosBundles = action.payload.iosBundles
        },
        setSelectedBundle: (state, action) => {
            state.selectedBundle = action.payload
        },
        resetBundleData: () => initialState,
    },
})

export const {
    setSelectedTab,
    setBundleLists,
    setSelectedBundle,
    resetBundleData,
} = bucketSlice.actions

export default bucketSlice.reducer
