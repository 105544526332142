import ApiService from './ApiService'
import { AuthEndpoints } from 'constants/endpoint.constants'
import store from '../store'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: AuthEndpoints.LOGIN,
        method: 'post',
        data,
    })
}

export async function apiGetUserData(data) {
    return ApiService.fetchData({
        url: AuthEndpoints.GET_USER_DATA,
        method: 'get',
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: AuthEndpoints.SIGNUP,
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: AuthEndpoints.SEND_FORGOT_PASS_LINK,
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: AuthEndpoints.FORGOT_PASS_RESET,
        method: 'post',
        data,
    })
}

export async function apiVerifyOtp(data) {
    return ApiService.fetchData({
        url: AuthEndpoints.VERIFY_OTP,
        method: 'post',
        data,
    })
}

export async function apiSendOtp(data) {
    return ApiService.fetchData({
        url: AuthEndpoints.SEND_OTP,
        method: 'post',
        data,
    })
}

export async function apiVerifyForgotPassLink(data) {
    return ApiService.fetchData({
        url: AuthEndpoints.VERIFY_FORGOT_PASS_LINK,
        method: 'post',
        data,
    })
}

export async function apiLogoutUser(data) {
    return ApiService.fetchData({
        url: AuthEndpoints.LOGOUT_USER,
        method: 'post',
        data,
    })
}
