import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedProjectName: '',
    selectedProjectId: '',
    projectMembers: null,
    selectedProjectAccess: '',
    selectedProjectData: null,
    projects: [],
}
export const projectSlice = createSlice({
    name: 'dashboard/project',
    initialState,
    reducers: {
        setProjectMembers: (state, action) => {
            state.projectMembers = action.payload
        },
        setSelectedProject: (state, action) => {
            state.selectedProjectName = action.payload.name
            state.selectedProjectId = action.payload.projectId
            state.selectedProjectAccess = action.payload.projectAccess
            state.selectedProjectData = action.payload.selectedProjectData
        },
        setProjectList: (state, action) => {
            state.projects = action.payload
        },
        resetProjectData: () => initialState,
        clearSelectedProject: (state) => {
            state.selectedProjectName = ''
            state.selectedProjectId = ''
            state.selectedProjectAccess = ''
            state.projectMembers = null
        },
    },
})

export const {
    setSelectedProject,
    setProjectList,
    clearSelectedProject,
    resetProjectData,
    setProjectMembers,
} = projectSlice.actions

export default projectSlice.reducer
